import { api } from 'constants/API';

import axios from 'axios';
import Cookies from 'universal-cookie';

import { UserInfo } from 'types/UserInfo';
import { SAM_JWT_ACCESS_UID } from 'utils/cookie';

const dataLayer = (window as any).dataLayer || [];

export const trackUserInfo = async () => {
  try {
    const response = await axios.get<UserInfo>(api.getUserInfo);
    const userInfo = response.data;
    const internalDomains = ['impact', 'spglobal', 'ihsmarkit', 'ihs', 'epam', 'test.ciam'].map(
      (name) => `${name}.com`,
    );
    const uidCookie = new Cookies().get(SAM_JWT_ACCESS_UID);
    const userDomain = userInfo.userDetails.email.split('@')[1].toLowerCase();
    const isInternal = internalDomains.includes(userDomain);
    dataLayer.push({
      event: 'load',
      user: [
        {
          id: uidCookie || userInfo.userId, // OKTA UID or NIMS User ID
          organization: userInfo.userDetails.company,
          internal: isInternal,
        },
      ],
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('[WARN] fetching user info:', error);
  }
};

export const trackVirtualPageview = () => {
  const vPagePath = window.location.href;
  const vPageTitle = document.title;

  dataLayer.push({
    event: 'VirtualPageview',
    VPagePath: vPagePath,
    VPageTitle: vPageTitle,
  });
};

const handleClick = (linkPath: string, linkText: string, eventName: string) => {
  const url = new URL(window.location.href);
  const linkUrl = `${url.protocol}//${url.host}${linkPath}`;
  dataLayer.push({
    event: eventName,
    link_text: linkText,
    link_url: linkUrl,
  });
};

export const headerNavClick = (linkPath: string) => {
  const linkText: string | undefined = linkPath.split('%2F').pop()?.toUpperCase().replace(/-/g, ' ');
  handleClick(linkPath, linkText || '', 'header_nav_click');
};

export const predictMapClick = (uwi: string) => {
  dataLayer.push({
    event: 'esi_mapclick_predict',
    uwi,
  });
};

export const menuTabClick = (linkText: string, tabName: string) => {
  dataLayer.push({
    event: 'click_tab',
    link_text: linkText,
    tab_name: tabName,
  });
};

export const addWellToMapClick = () => {
  dataLayer.push({
    event: 'user_feature_click',
    feature_type: 'Well Planner features',
    feature_text: 'Add Well',
    location: 'Energy Studio Impact : Well Planner',
  });
};

export const landingPageDashboardClick = (dashboardName: string) => {
  dataLayer.push({
    event: 'esi_dashboard',
    interaction_type: 'open',
    dashboard_name: dashboardName,
    location: 'Energy Studio Impact - Landing Page',
  });
};

export const landingPageCreateNewDashboardClick = () => {
  dataLayer.push({
    event: 'esi_dashboard',
    interaction_type: 'create',
    dashboard_name: 'new',
    location: 'Energy Studio Impact - Landing Page',
  });
};

export const runScenarioClick = (uwi: string) => {
  dataLayer.push({
    event: 'esi_scenario_economics',
    interaction_type: 'Run',
    scenario_name: 'user_defined',
    uwi,
    location: 'Energy Studio Impact - Economics',
  });
};

export const resetScenarioClick = (uwi: string) => {
  dataLayer.push({
    event: 'esi_scenario_economics',
    interaction_type: 'Reset',
    scenario_name: 'user_defined',
    uwi,
    location: 'Energy Studio Impact - Economics',
  });
};
