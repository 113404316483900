import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type, warning } = theme.palette;
  const { fontWeightRegular } = theme.typography;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    dialogPaper: {
      minWidth: 480,
      maxWidth: 560,
      background: isDarkTheme ? colors.grey56 : colors.primaryWhite,
    },
    dialogTitle: {
      backgroundColor: warning.main,
      padding: '10px 24px',
    },
    dialogContent: {
      display: 'flex',
    },
    dialogActions: {
      padding: 12,
    },
    title: {
      color: colors.grey60,
    },
    message: {
      fontWeight: fontWeightRegular as number,
      lineHeight: '24px',
      width: '100%',
    },
    icon: {
      color: warning.main,
      fontSize: 32,
      marginRight: 8,
    },
    bodyContent: {
      height: 100,
      overflow: 'scroll',
    },
  });
});
